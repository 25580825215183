.l-reels {
	margin-top: var(--space-40--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.l-reels {
		margin-top: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-reels {
		margin-top: var(--space-120--rem);
	}
}
