.teaser-list-a {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.teaser-list-a__item {
	display: block;
	margin: 0;
	margin-top: var(--space-20--rem);
	margin-bottom: var(--space-20--rem);
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-a__item {
		margin-top: var(--space-60--rem);
		margin-bottom: var(--space-60--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-list-a__item {
		margin-top: var(--space-100--rem);
		margin-bottom: var(--space-100--rem);
	}
}
