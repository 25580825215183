.director-intro {
	margin: 0 auto;
	text-align: center;
	text-wrap: pretty;
	max-width: 400px;
}

.director-intro__text {
	margin: 0;
}

.director-intro__text + .director-intro__text {
	margin-block-start: var(--space-15--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.director-intro__text + .director-intro__text {
		margin-block-start: var(--space-30--rem);
	}
}
