.teaser-c {
	--cursor-thumbnail--left: 0px;
	--cursor-thumbnail--top: 0px;

	text-align: center;
}

.teaser-c__title {
	color: currentColor;
	text-decoration: none;
	text-wrap: balance;
}

.teaser-c__thumbnail {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: calc(var(--cursor-thumbnail--top) + 24px);
	left: calc(var(--cursor-thumbnail--left) + 16px);
	transition: opacity 128ms;
}

.teaser-c:hover [data-show] .teaser-c__thumbnail {
	opacity: 1;
}
