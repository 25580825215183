:root {
	/* #region Colors */
	--color-foreground: rgb(0 177 239);
	--color-background: rgb(255 255 255);
	--color-grey-40: rgb(108 112 108);
	--color-cyan: rgb(0 177 239);
	--color-black: rgb(0 0 0);
	--color-off-black: rgb(25 24 24);
	--color-white: rgb(255 255 255);
	--color-warning: rgb(242 149 0); /* Orange - contrast 4.5 & 7 */
	--color-error: rgb(207 0 0); /* Red - contrast 4.5 & 7 */
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "ABCO Include", "ABCO Include Fallback", "Helvetica Neue", sans-serif;
	--font-sans-expanded: "ABCO Include Expanded", "ABCO Include", "ABCO Include Fallback", "Helvetica Neue", sans-serif;
	/* #endregion Typography */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-2--px: 2px;
	--space-2--rem: 0.125rem;
	--space-3--px: 3px;
	--space-3--rem: 0.1875rem;
	--space-4--px: 4px;
	--space-4--rem: 0.25rem;
	--space-5--px: 5px;
	--space-5--rem: 0.3125rem;
	--space-6--px: 6px;
	--space-6--rem: 0.375rem;
	--space-7--px: 7px;
	--space-7--rem: 0.4375rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-12--px: 12px;
	--space-12--rem: 0.75rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-24--px: 24px;
	--space-24--rem: 1.5rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-32--px: 32px;
	--space-32--rem: 2rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-50--px: 50px;
	--space-50--rem: 3.125rem;
	--space-60--px: 60px;
	--space-60--rem: 3.75rem;
	--space-80--px: 80px;
	--space-80--rem: 5rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	--space-120--px: 120px;
	--space-120--rem: 7.5rem;
	/* #endregion Spacers */
	--z-index-modal-dialog: 9999;
	--z-index-site-header: 10;
	--z-index-video-text-overlay: 20;
	--z-index-teaser-text-overlay: 20;
	--z-index-video-controls: 30;
}

[data-theme="blue"] {
	--color-foreground: rgb(255 255 255);
	--color-background: rgb(0 177 239);
}

[data-theme="white"] {
	--color-foreground: rgb(0 177 239);
	--color-background: rgb(255 255 255);
}

@scope ([data-theme="blue"]) to ([data-theme] > * ) {
	:scope {
		--color-foreground: rgb(255 255 255);
		--color-background: rgb(0 177 239);
	}
}

@scope ([data-theme="white"]) to ([data-theme] > * ) {
	:scope {
		--color-foreground: rgb(0 177 239);
		--color-background: rgb(255 255 255);
	}
}
