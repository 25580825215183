.labeled-text-grid {
	display: grid;
	row-gap: var(--space-15--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.labeled-text-grid {
		column-gap: var(--space-15--px);
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

.labeled-text-grid__label {
	margin: 0;
}

.labeled-text-grid__items {
	display: grid;
	row-gap: var(--space-15--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.labeled-text-grid__items {
		column-gap: var(--space-15--px);
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.labeled-text-grid__items {
		grid-column: 2 / span 3;
	}
}

.labeled-text-grid__item p {
	margin-block: var(--space-15--rem);
}

.labeled-text-grid__item p:first-child {
	margin-block-start: 0;
}

.labeled-text-grid__item p:last-child {
	margin-block-end: 0;
}
