.project-filter {
	margin-block-end: var(--space-24--rem);
}

.project-filter__heading {
	display: inline-flex;
	column-gap: var(--space-10--px);
	margin: 0;
	align-items: center;
}

.project-filter__accordion-trigger {
	-mrh-resets: button;
	background-color: transparent;
	border: 1px solid var(--color-foreground);
	border-radius: 9999px;
	color: var(--color-foreground);
	font-size: initial;
	line-height: unset;
	padding-inline: var(--space-10--px);
	padding-block: var(--space-3--rem);
	transition:
		color 256ms,
		background-color 256ms,
		border-color 256ms;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.project-filter__accordion-trigger {
		padding-inline: var(--space-12--px);
		padding-block: var(--space-4--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.project-filter__accordion-trigger {
		padding-inline: var(--space-15--px);
		padding-block: var(--space-5--rem);
	}
}

.project-filter__accordion-trigger[aria-expanded="true"] {
	background-color: var(--color-foreground);
	border-color: transparent;
	color: var(--color-background);
}

.project-filter__accordion-trigger__title {
	display: inline-block;
	transition: transform 256ms;
}

.project-filter__accordion-trigger:hover .project-filter__accordion-trigger__title {
	transform: scale(1.15);
}

.project-filter__clear-filter {
	display: none;
}

.project-filter__accordion-trigger[aria-expanded="true"] + .project-filter__clear-filter,
.project-filter__clear-filter[data-has-current] {
	display: block;
}
/* #endregion Handle */

/* #region Panel */
.project-filter__accordion-panel:not([aria-hidden="true"]) {
	margin-block-start: var(--space-15--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.project-filter__accordion-panel:not([aria-hidden="true"]) {
		margin-block-start: var(--space-30--rem);
	}
}

.project-filter__accordion-panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.project-filter__accordion-panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
		padding: 0;
	}
}

.project-filter__panel__inner {
	display: grid;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.project-filter__panel__inner {
		column-gap: var(--space-15--px);
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
}

.project-filter__filter {
	margin-block-start: var(--space-10--rem);
}

.project-filter__filter:first-child {
	margin-block-start: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.project-filter__filter {
		margin-block-start: 0;
	}
}

.project-filter__filter__label {
	border-bottom: 2px solid var(--color-foreground);
	margin: 0;
	padding-block-end: var(--space-10--rem);
}

.project-filter__filter__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	padding-block-start: var(--space-5--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.project-filter__filter__list {
		padding-block-start: var(--space-7--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.project-filter__filter__list {
		padding-block-start: var(--space-10--rem);
	}
}

.project-filter__filter__item {
	margin: 0;
}
/* #endregion Panel */
