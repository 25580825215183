* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
p {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.24px;
	line-height: 1.4;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	body,
	p {
		font-size: 0.875rem;
		letter-spacing: -0.28px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	body,
	p {
		font-size: 0.9375rem;
		letter-spacing: -0.3px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
	font-family: var(--font-sans);
	margin-top: 1.875rem;
	margin-bottom: 1.875rem;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, li):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, li):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, ul, li):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.type-d,
h1 {
	font-family: var(--font-sans);
	font-size: 2rem;
	font-style: normal;
	font-weight: 900;
	letter-spacing: -0.72px;
	line-height: 0.9;
}

/* from-xxsmall,min-width-384px */
@media (width >= 24rem) {
	.type-d,
	h1 {
		font-size: 2.625rem;
		letter-spacing: -0.84px;
	}
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.type-d,
	h1 {
		font-size: clamp(2.625rem, 1.10714285rem + 7.58928571vw, 4.75rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-d,
	h1 {
		font-size: 4.75rem;
		font-size: clamp(4.75rem, 3.03571428rem + 3.57142857vw, 6.25rem);
		letter-spacing: -1.52px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-d,
	h1 {
		font-size: 6.25rem;
		font-size: clamp(6.25rem, 1.42857142rem + 5.35714285vw, 10rem);
		letter-spacing: -2px;
	}
}

.type-a,
h2,
blockquote p {
	font-family: var(--font-sans);
	font-size: 1.875rem;
	font-style: normal;
	font-weight: 900;
	letter-spacing: -0.6px;
	line-height: 0.8;
}

/* from-xxsmall,min-width-384px */
@media (width >= 24rem) {
	.type-a,
	h2,
	blockquote p {
		font-size: 2.25rem;
		letter-spacing: -0.72px;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-a,
	h2,
	blockquote p {
		font-size: 4rem;
		letter-spacing: -1.28px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-a,
	h2,
	blockquote p {
		font-size: 4.5rem;
		letter-spacing: -1.44px;
	}
}

.type-e,
h3,
cite {
	font-family: var(--font-sans);
	font-size: 2.25rem;
	font-weight: 900;
	letter-spacing: -0.72px;
	line-height: 0.8;
}

.type-f,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 600;
	letter-spacing: -0.24px;
	line-height: 1.4;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-f,
	h4,
	h5,
	h6 {
		font-size: 0.875rem;
		letter-spacing: -0.28px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-f,
	h4,
	h5,
	h6 {
		font-size: 0.9375rem;
		letter-spacing: -0.3px;
	}
}

.type-b {
	font-family: var(--font-sans);
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.24px;
	line-height: 1.4;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-b {
		font-size: 0.875rem;
		letter-spacing: -0.28px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-b {
		font-size: 0.9375rem;
		letter-spacing: -0.3px;
	}
}

.type-b:any-link {
	color: currentColor;
	display: inline-block;
	overflow: hidden;
}

.type-b:any-link span {
	display: inline-block;
	text-decoration: none;
	text-shadow: 0px 2rem currentColor;
	text-shadow: 0px 1lh currentColor;
	transition: transform 256ms;
}

.type-b:any-link:hover span {
	transform: translateY(-2rem);
	transform: translateY(-1lh);
}

[data-is-current] .type-b:any-link {
	font-weight: 600;
}

.type-c {
	font-family: var(--font-sans);
	font-size: 0.6875rem;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.33px;
	line-height: 1.4;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.type-c {
		font-size: 0.8125rem;
		letter-spacing: -0.39px;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.type-c {
		font-size: 0.875rem;
		letter-spacing: -0.42px;
	}
}

i,
em,
b,
strong {
	font-weight: 600;
}

cite {
	font-style: normal;
}

blockquote {
	margin-inline: 20px;
	margin-block: 1.25rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	blockquote {
		margin-inline: 40px;
		margin-block: 2.5rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	blockquote {
		margin-inline: 60px;
		margin-block: 3.75rem;
	}
}
