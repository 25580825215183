.awards {
	display: flex;
	flex-wrap: wrap;
	row-gap: 0.3125rem;
	column-gap: 10px;
	width: 100%;
}

.awards[data-align="right"] {
	justify-content: flex-end;
}

.awards__item {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
}

.awards__item::before,
.awards__item::after {
	background-color: var(--color-foreground);
	content: "";
	mask-image: url("../../images/laurel-left.svg");
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	width: 0.4125rem;
	height: 1.1375rem;
}

.awards__item::before {
	margin-right: 2px;
}

.awards__item::after {
	margin-left: 2px;
	transform: rotate(180deg);
}

.awards__item__text {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	color: var(--color-foreground);
	hyphens: auto;
	overflow: hidden;
}
