.pagination {
	display: flex;
	flex-direction: row;
	list-style: none;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.pagination {
	--pagination--arrows-display: block;
	--pagination--numbers-display: none;
	--pagination--is-current-display: block;
}

.pagination:is([data-pages="1"], [data-pages="2"], [data-pages="3"], [data-pages="4"]) {
	--pagination--arrows-display: none;
	--pagination--numbers-display: block;
	--pagination--is-current-display: block;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.pagination:is([data-pages="5"]) {
		--pagination--arrows-display: none;
		--pagination--numbers-display: block;
		--pagination--is-current-display: block;
	}
}

.pagination__item {
	--icon-rotation: 0deg;

	margin-inline: var(--space-20--px);
	margin-block: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.pagination__item {
		margin-inline: var(--space-40--px);
	}
}

.pagination__item[data-display="arrow"] {
	display: var(--pagination--arrows-display);
}

.pagination__item[data-display="number"] {
	display: var(--pagination--numbers-display);
}

.pagination__item[data-is-current] {
	display: var(--pagination--is-current-display);
}

.pagination__item:first-child {
	margin-inline-start: 0;
}

.pagination__item:last-child {
	margin-inline-end: 0;
}

.pagination__item .icon {
	transform: translateY(0.125rem) rotate(var(--icon-rotation));
	transform-origin: center;
	width: 0.875em;
	height: 0.875em;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.pagination__item .icon {
		transform: translateY(0.25rem) rotate(var(--icon-rotation));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.pagination__item .icon {
		transform: translateY(0.375rem) rotate(var(--icon-rotation));
	}
}

.pagination__item__link {
	display: inline-block;
}

.pagination__item[data-display="number"] .pagination__item__link {
	overflow: hidden;
}

.pagination__item[data-display="number"] .pagination__item__link span {
	display: inline-block;
	text-decoration: none;
	text-shadow: 0px 2rem currentColor;
	text-shadow: 0px 1lh currentColor;
	transition: transform 384ms;
}

.pagination__item[data-display="number"]:hover .pagination__item__link span {
	transform: translateY(-2rem);
	transform: translateY(-1lh);
}

.pagination__item__current {
	-webkit-text-stroke-color: var(--color-foreground);
	-webkit-text-stroke-width: 0.125rem;
	color: transparent;
	display: inline-block;
	font-family: var(--font-sans-expanded);
	transform: scale(0.97);
}
