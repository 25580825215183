.scroll-reveal-text-container {
	text-align: center;
}

.scroll-reveal-text__middle__inner {
	display: none;
}

@supports (animation-timeline: scroll(root)) and (interpolate-size: allow-keywords) {
	.scroll-reveal-text {
		interpolate-size: allow-keywords;
		overflow: hidden;
		white-space: nowrap;
		max-width: 100%;
	}

	.scroll-reveal-text__middle[data-side] {
		animation: SCROLL_REVEAL_TEXT linear forwards;
		animation-range: 15vh 115vh;
		animation-timeline: view();
		display: inline-flex;
		overflow: hidden;
		width: 0.4125ch;
	}

	.scroll-reveal-text__middle[data-side="after"] {
		padding-inline-end: 1px;
		justify-content: end;
	}

	.scroll-reveal-text__middle__fallback {
		display: none;
	}

	.scroll-reveal-text__middle__inner {
		display: inline;
	}
}

@keyframes SCROLL_REVEAL_TEXT {
	from {
		width: 0.4125ch;
	}

	to {
		width: auto;
	}
}
