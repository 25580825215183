.site-footer {
	background-color: var(--color-background);
	padding-block: var(--space-30--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer {
		padding-block: var(--space-60--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer {
		padding-block: var(--space-100--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a {
		display: flex;
		flex-direction: row;
		column-gap: var(--space-50--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__primary-navigation {
		order: 2;
		width: 50%;
	}
}

.site-footer__area-a__primary-navigation .primary-navigation {
	justify-content: center;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__primary-navigation .primary-navigation {
		justify-content: flex-end;
	}
}

.site-footer__area-a__newsletter {
	padding-block-start: var(--space-30--rem);
	text-align: center;
	text-wrap: balance;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__newsletter {
		text-align: left;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__area-a__newsletter {
		padding-block-start: var(--space-50--rem);
	}
}

.site-footer__area-a__newsletter:first-child {
	padding-block-start: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__newsletter {
		padding-block-start: 0;
		width: 50%;
	}
}

.site-footer__area-a__newsletter__text {
	margin-inline: auto;
	max-width: 400px;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__newsletter__text {
		margin-inline: 0;
	}
}

.site-footer__area-a__newsletter .form {
	margin: 0 auto;
	text-align: left;
	width: max-content;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-a__newsletter .form {
		margin: 0;
	}
}

.site-footer__area-a__newsletter .form__text__input {
	width: 10rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__area-a__newsletter .form__text__input {
		width: 13rem;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__area-a__newsletter .form__text__input {
		width: 15rem;
	}
}

.site-footer__area-b {
	display: flex;
	margin-block-start: var(--space-30--rem);
	justify-content: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__area-b {
		margin-block-start: var(--space-50--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-b {
		justify-content: flex-end;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__area-b {
		margin-block-start: var(--space-60--rem);
	}
}

.site-footer__area-c {
	display: flex;
	flex-direction: column;
	row-gap: var(--space-30--rem);
	padding-block-start: var(--space-30--rem);
	align-items: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-footer__area-c {
		row-gap: var(--space-50--rem);
		padding-block-start: var(--space-50--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.site-footer__area-c {
		flex-direction: row;
		justify-content: space-between;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-footer__area-c {
		padding-block-start: var(--space-60--rem);
	}
}

.site-footer__area-c__legal-navigation__list {
	display: flex;
	column-gap: var(--space-15--px);
	list-style-type: none;
	padding: 0;
}

.site-footer__area-c__legal-navigation__list__item {
	margin: 0;
}

.site-footer__area-c__copyright {
	margin: 0;
}
