:root {
	color-scheme: light;
}

body {
	background-color: var(--color-background);
	color: var(--color-foreground);
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

[data-theme] {
	color: var(--color-foreground);
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin-inline: 0;
	margin-block: var(--space-32--rem);
	width: 100%;
	max-width: 100%;
}

table:first-child {
	margin-block-start: 0;
}

table:last-child {
	margin-block-end: 0;
}

.mr-table-scroll-container {
	overflow-x: auto;
}

.mr-table-scroll-container table {
	max-width: none;
}

th,
td {
	box-sizing: border-box;
	padding-inline: var(--space-32--px);
	padding-block: var(--space-32--rem);
	text-align: left;
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	margin: 0;
	margin-block-end: 2rem;
	padding-inline-start: 1.25rem;
}

:is(ol, ul):not([class]):last-child {
	margin-block-end: 0;
}

li :is(ol, ul):not([class]) {
	margin-block-end: 0;
	padding-block-start: 0.25rem;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

/* #region Shared link styles (WYSIWYG and components) */
:any-link {
	color: currentColor;
	text-decoration: none;
}

:any-link:hover {
}
/* #endregion Shared link styles (WYSIWYG and components) */

/* #region WYSIWYG link styles */
@property --link-background-progress {
	syntax: "<percentage>";
	initial-value: 0%;
	inherits: false;
}

:any-link:not([class]),
:any-link.default-link {
	--link-background-progress: 0%;

	background: linear-gradient(to top, var(--color-foreground) 0%, var(--color-foreground) var(--link-background-progress), transparent var(--link-background-progress));
	color: currentColor;
	display: inline;
	text-decoration: underline;
	text-decoration-color: var(--color-foreground);
	text-decoration-thickness: 2px;
	transition:
		color 256ms,
		--link-background-progress 256ms;
}

:any-link:not([class]):hover,
:any-link.default-link:hover {
	--link-background-progress: 100%;

	color: var(--color-background);
	text-decoration-color: var(--color-foreground);
	text-decoration-skip-ink: none;
}

p a:not([class]) {
	line-height: inherit;
}
/* #endregion WYSIWYG link styles */

mr-hover-animation {
	display: inline;
	display: contents;
}
