.text-50-50 {
	display: grid;
	row-gap: var(--space-30--rem);
	column-gap: var(--space-30--px);
	text-wrap: pretty;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.text-50-50 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.text-50-50 {
		column-gap: var(--space-50--px);
	}
}

.text-50-50__column a + br + a {
	display: inline-block;
	margin-block-start: var(--space-10--rem);
}
