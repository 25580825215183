.video-full-controls {
	background-color: rgb(0 0 0 / 0.2);
	display: block;
	width: 100%;
	height: auto;
}

@supports (aspect-ratio: 1) {
	.video-full-controls {
		--video-aspect-ratio: 16 / 9;

		aspect-ratio: var(--video-aspect-ratio);
	}
}

.video-full-controls-container {
	background-color: var(--color-background);
	display: block;
}

.video-full-controls__controls {
	display: flex;
	flex-direction: row;
	column-gap: var(--space-30--px);
	padding-block: var(--space-2--rem);
	justify-content: center;
}

.video-full-controls__controls .icon {
	display: block;
	width: 0.875rem;
	height: 0.625rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video-full-controls__controls .icon {
		width: 1rem;
		height: 0.6875rem;
	}
}

.video-full-controls__play-pause-toggle__button {
	-mrh-resets: button;
	color: var(--color-foreground);
	padding: 8px;
}

.video-full-controls__play-pause-toggle .icon-pause,
[data-playing].video-full-controls__play-pause-toggle .icon-arrow {
	display: none;
}

[data-playing].video-full-controls__play-pause-toggle .icon-pause {
	display: block;
}

.video-full-controls__rewind__button {
	-mrh-resets: button;
	color: var(--color-foreground);
	padding: 8px;
}

.video-full-controls__mute-toggle__button {
	-mrh-resets: button;
	color: var(--color-foreground);
	padding: 8px;
}

.video-full-controls__mute-toggle__button .icon-sound-off,
[data-muted] .video-full-controls__mute-toggle__button .icon-sound-on {
	display: none;
}

[data-muted] .video-full-controls__mute-toggle__button .icon-sound-off,
.video-full-controls__mute-toggle__button .icon-sound-on {
	display: block;
}
