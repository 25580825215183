.navigation-overlay {
	box-sizing: border-box;
	clip-path: inset(0 0 0 0);
	display: flex;
	flex-direction: column;
	padding-block-start: var(--space-10--rem);
	padding-block-end: var(--space-30--rem);
	height: 100vh;
	height: 100svh;
}

.navigation-overlay__header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.navigation-overlay__close__button {
	-mrh-resets: button;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}

.navigation-overlay__close__button .icon {
	transform: rotate(45deg);
	transform-origin: center;
	width: 100%;
	height: 100%;
}

.navigation-overlay__content {
	display: flex;
	flex-direction: column;
	padding-block-start: var(--space-10--rem);
	justify-content: space-between;
	height: 100%;
}

.navigation-overlay__primary-navigation {
	display: block;
	list-style: none;
	padding: 0;
}

.navigation-overlay__primary-navigation__item {
	line-height: 0;
	margin: 0;
}

.navigation-overlay__primary-navigation__item:not(:first-child) {
	margin-block-start: var(--space-10--rem);
}

.navigation-overlay__primary-navigation__link {
	display: inline-block;
}

[data-is-current] .navigation-overlay__primary-navigation__link {
	-webkit-text-stroke-color: var(--color-foreground);
	-webkit-text-stroke-width: 0.075rem;
	color: transparent;
	font-family: var(--font-sans-expanded);
}
