.wrapper {
	box-sizing: border-box;
	padding-right: var(--space-10--px);
	padding-left: var(--space-10--px);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper {
		padding-right: var(--space-60--px);
		padding-left: var(--space-60--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.wrapper {
		padding-right: var(--space-100--px);
		padding-left: var(--space-100--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.wrapper[data-size="wide"] {
		padding-right: var(--space-15--px);
		padding-left: var(--space-15--px);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.wrapper[data-size="wide"] {
		padding-right: var(--space-20--px);
		padding-left: var(--space-20--px);
	}
}
