.contact-info {
	display: grid;
	row-gap: var(--space-15--rem);
	column-gap: var(--space-15--px);
	grid-template-areas:
		"contact-options address"
		"social social";
	grid-template-rows: auto;
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.contact-info {
		grid-template-areas: "contact-options address social social";
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

.contact-info__contact-options {
	grid-area: contact-options;
	margin: 0;
}

.contact-info__address {
	grid-area: address;
	margin: 0;
}

.contact-info__social {
	grid-area: social;
}
