.teaser-a {
	border-radius: var(--space-15--rem);
	overflow: hidden;
	position: relative;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-a {
		border-radius: var(--space-30--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a {
		border-radius: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a {
		border-radius: var(--space-60--rem);
	}
}

.teaser-a__content {
	background-color: rgb(0 0 0 / 0.2);
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-inline: var(--space-15--px);
	padding-block: var(--space-15--rem);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-video-text-overlay);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-a__content {
		padding-inline: var(--space-30--px);
		padding-block: var(--space-30--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a__content {
		padding-inline: var(--space-40--px);
		padding-block: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__content {
		padding-inline: var(--space-60--px);
		padding-block: var(--space-60--rem);
	}
}

.teaser-a__tags {
	display: flex;
	flex-grow: 1;
	column-gap: var(--space-5--px);
	order: 1;
	justify-content: space-between;
}

.teaser-a__tags__category-and-clients__category {
	border: 1px solid var(--color-foreground);
	padding-inline: var(--space-7--px);
	padding-block: var(--space-3--rem);
	white-space: nowrap;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a__tags__category-and-clients__category {
		padding-inline: var(--space-12--px);
		padding-block-start: var(--space-5--rem);
		padding-block-end: var(--space-7--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__tags__category-and-clients__category {
		padding-inline: var(--space-15--px);
		padding-block-start: var(--space-7--rem);
		padding-block-end: var(--space-10--rem);
	}
}

.teaser-a__tags__category-and-clients__category[data-style="dashed"] {
	border-style: dashed;
	border-radius: var(--space-40--px);
}

.teaser-a__tags__category-and-clients__client {
	display: block;
	margin-block-start: var(--space-5--px);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-a__tags__category-and-clients__client {
		display: inline;
		margin-inline-start: var(--space-10--px);
		margin-block-start: 0;
	}
}

.teaser-a__title {
	margin-inline-end: 2rem;
	margin-block-start: 0.5rem;
	order: 2;
	text-wrap: balance;
	max-width: 600px;
}

.teaser-a__details {
	margin-inline-end: 2rem;
	margin-block-start: var(--space-15--rem);
	order: 3;
	overflow-y: auto;
	padding-block: 1px;
	max-width: 600px;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-a__details {
		margin-block-start: var(--space-30--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a__details {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__details {
		margin-block-start: var(--space-60--rem);
	}
}

.teaser-a__summary {
	list-style-type: none;
	padding-inline-start: var(--space-20--px);
	text-indent: calc(-1 * var(--space-20--px));
}

.teaser-a__details[open] .teaser-a__summary {
	padding-block-end: var(--space-7--rem);
}

.teaser-a__summary::marker {
	display: none;
}

.teaser-a__summary::-webkit-details-marker {
	display: none;
}

.teaser-a__summary__icon {
	border: 1px solid var(--color-foreground);
	border-radius: 9999px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	transform: translateY(-2px);
	width: var(--space-15--rem);
	height: var(--space-15--rem);
}

.teaser-a__summary__icon .icon {
	width: var(--space-5--rem);
	height: var(--space-5--rem);
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__summary__icon .icon {
		width: var(--space-6--rem);
		height: var(--space-6--rem);
	}
}

.teaser-a__summary__icon .icon-minus,
.teaser-a__details[open] .teaser-a__summary__icon .icon-plus {
	display: none;
}

.teaser-a__details[open] .teaser-a__summary__icon .icon-minus {
	display: block;
}

.teaser-a__links {
	display: inline-flex;
	column-gap: var(--space-15--px);
	margin-inline-end: 2rem;
	margin-block-start: var(--space-15--rem);
	order: 4;
	max-width: 600px;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.teaser-a__links {
		margin-block-start: var(--space-30--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-a__links {
		margin-block-start: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-a__links {
		margin-block-start: var(--space-60--rem);
	}
}
