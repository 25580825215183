.social-navigation {
	display: inline-flex;
	column-gap: var(--space-30--px);
	list-style-type: none;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.social-navigation {
		column-gap: var(--space-40--px);
	}
}

.social-navigation__item {
	margin: 0;
}

.social-navigation__item__link {
	--link-background-progress: 0%;

	background: linear-gradient(to top, var(--color-foreground) 0%, var(--color-foreground) var(--link-background-progress), var(--color-background) var(--link-background-progress));
	border-radius: 3px;
	display: block;
	margin: -3px;
	padding: 3px;
	transition: --link-background-progress 256ms;
}

.social-navigation__item__link:hover {
	--link-background-progress: 100%;
}

.social-navigation__item__link__icon {
	--link-background-progress: 0%;

	background: linear-gradient(to top, var(--color-background) 0%, var(--color-background) var(--link-background-progress), var(--color-foreground) var(--link-background-progress));
	display: block;
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	transition: opacity 256ms ease-in-out;
	transition: --link-background-progress 256ms;
	width: 1rem;
	height: 1rem;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.social-navigation__item__link__icon {
		width: 1.25rem;
		height: 1.25rem;
	}
}

.social-navigation__item__link:hover .social-navigation__item__link__icon {
	--link-background-progress: 100%;
}

.social-navigation__item__link__icon {
	mask-image: url("../../../images/external-link.svg");
}

[href*="facebook.com"] > .social-navigation__item__link__icon {
	mask-image: url("../../../images/facebook.svg");
}

[href*="instagram.com"] > .social-navigation__item__link__icon {
	mask-image: url("../../../images/instagram.svg");
}

[href*="linkedin.com"] > .social-navigation__item__link__icon {
	mask-image: url("../../../images/linkedin.svg");
}

[href*="threads.net"] > .social-navigation__item__link__icon {
	mask-image: url("../../../images/threads.svg");
}

[href*="x.com"] > .social-navigation__item__link__icon {
	mask-image: url("../../../images/x.svg");
}
