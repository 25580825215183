.teaser-list-c {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.teaser-list-c__item {
	margin-block: var(--space-15--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-c__item {
		margin-block: var(--space-10--rem);
	}
}

.teaser-list-c__item:first-child {
	margin-block-start: 0;
}

.teaser-list-c__item:last-child {
	margin-block-end: 0;
}
