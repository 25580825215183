.project-intro {
	display: grid;
	row-gap: var(--space-10--rem);
	grid-template-areas:
		"text-and-awards"
		"metadata";
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.project-intro {
		row-gap: var(--space-15--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.project-intro {
		column-gap: var(--space-15--px);
		grid-template-areas: "metadata text-and-awards";
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.project-intro__text-and-awards {
	grid-area: text-and-awards;
}

.project-intro__text {
	margin: 0;
}

.project-intro__text-and-awards .awards {
	margin-block-start: var(--space-10--rem);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.project-intro__text-and-awards .awards {
		margin-block-start: var(--space-15--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.project-intro__text-and-awards .awards {
		margin-block-start: var(--space-20--rem);
	}
}

.project-intro__links {
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-10--rem);
	column-gap: var(--space-20--px);
	margin-block-start: var(--space-10--rem);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.project-intro__links {
		margin-block-start: var(--space-15--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.project-intro__links {
		margin-block-start: var(--space-20--rem);
	}
}

.project-intro__metadata {
	grid-area: metadata;
}

.project-intro__metadata__list {
	margin: 0;
}

.project-intro__metadata__list__item {
	display: grid;
	column-gap: var(--space-15--px);
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.project-intro__metadata__item-description {
	margin: 0;
}
