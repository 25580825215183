.hero-reels {
	margin-inline: auto;
	margin-block: var(--space-40--rem);
	text-align: center;
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.hero-reels {
		margin-block: var(--space-50--rem);
		max-width: 50vw;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-reels {
		margin-block: var(--space-80--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.hero-reels {
		margin-block: var(--space-120--rem);
	}
}

.hero-reels__title:not(:last-child) {
	margin-block-end: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.hero-reels__title:not(:last-child) {
		margin-block-end: var(--space-30--rem);
	}
}
