.labeled-text {
	display: grid;
	row-gap: var(--space-15--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.labeled-text {
		column-gap: var(--space-15--px);
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

.labeled-text__label {
	margin: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.labeled-text__text {
		grid-column: 2 / span 2;
	}
}

.labeled-text__text p {
	margin-block: var(--space-15--rem);
}

.labeled-text__text p:first-child {
	margin-block-start: 0;
}

.labeled-text__text p:last-child {
	margin-block-end: 0;
}
