.teaser-list-b {
	display: grid;
	row-gap: var(--space-30--rem);
	column-gap: var(--space-15--px);
	list-style-type: none;
	padding: 0;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-list-b {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}

.teaser-list-b__item {
	display: block;
	margin: 0;
}
