.text-landing-template {
	margin: 0 auto;
	text-align: center;
	text-wrap: pretty;
	max-width: 50vw;
}

.text-landing-template :is(ul, ol) {
	text-align: left;
}
