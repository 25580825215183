.primary-navigation {
	display: flex;
	flex-wrap: wrap;
	column-gap: 20px;
	list-style: none;
	padding: 0;
	justify-content: end;
}

.primary-navigation__item {
	color: var(--color-foreground);
	display: flex;
	margin: 0;
	align-items: center;
}
