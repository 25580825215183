.arrow-link {
	color: var(--color-foreground);
	text-decoration: none;
}

/* from-small,tablet-p,min-width-768px */
@media screen and (width >= 48rem) and (hover) {
	.arrow-link {
		display: inline-block;
		overflow: hidden;
		position: relative;
	}
}

.arrow-link .arrow-link__label {
	hyphens: auto;
}

.arrow-link .arrow-link__label[data-position="after"] {
	margin-inline-start: 4px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.arrow-link .arrow-link__label[data-position="after"] {
		margin-inline-start: 5px;
	}
}

.arrow-link .arrow-link__label[data-position="before"] {
	display: none;
	margin-inline-end: 4px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.arrow-link .arrow-link__label[data-position="before"] {
		margin-inline-end: 5px;
	}
}

/* from-small,tablet-p,min-width-768px */
@media screen and (width >= 48rem) and (hover) {
	.arrow-link .arrow-link__label[data-position="before"] {
		display: block;
		position: absolute;
		left: calc(-100% + 1ch);
	}
}

/* from-small,tablet-p,min-width-768px */
@media screen and (width >= 48rem) and (hover) {
	.arrow-link__track {
		display: inline-block;
		transition: transform 384ms ease-in-out;
	}

	.arrow-link:hover .arrow-link__track {
		transform: translateX(calc(100% - 0.8ch));
	}
}

.arrow-link .icon-arrow {
	width: 10px;
	height: 10px;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.arrow-link .icon-arrow {
		width: 11px;
		height: 11px;
	}
}
