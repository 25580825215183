.site-header-home {
	display: flex;
	flex-direction: row;
	padding-block-start: var(--space-10--rem);
	justify-content: space-between;
	align-items: flex-end;
	z-index: var(--z-index-site-header);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header-home {
		padding-block-start: var(--space-60--rem);
		align-items: center;
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.site-header-home {
		padding-block-start: var(--space-100--rem);
	}
}

.site-header-home__primary-navigation {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header-home__primary-navigation {
		display: block;
		margin-inline-start: 1rem;
	}
}

.site-header-home__overlay-navigation {
	display: block;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.site-header-home__overlay-navigation {
		display: none;
	}
}

.site-header-home__overlay-navigation__button {
	-mrh-resets: button;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}

.site-header-home__overlay-navigation__button .icon {
	width: 100%;
	height: 100%;
}
