.video {
	width: 100%;
}

.video-container .video {
	height: auto;
}

@supports (object-fit: cover) {
	.video-container[data-aspect-ratio] .video {
		object-fit: cover;
		height: 100%;
	}
}

.video-container {
	position: relative;
}

.video-container[data-aspect-ratio] {
	display: flex;
}

.video-container[data-aspect-ratio="1-1"] {
	aspect-ratio: 1 / 1;
}

.video-container[data-aspect-ratio="4-3"] {
	aspect-ratio: 4 / 3;
}

.video-container[data-aspect-ratio="16-9"] {
	aspect-ratio: 16 / 9;
}

/* up-to-small,max-width-767px */
@media (width < 48rem) {
	.video-container[data-aspect-ratio] {
		aspect-ratio: 1 / 1;
	}
}

.video-wrapper {
	width: 100%;
}

.video__controls-container {
	position: absolute;
	right: var(--space-15--px);
	bottom: var(--space-15--rem);
	left: auto;
	z-index: var(--z-index-video-controls);
}

/* from-xsmall,min-width-560px */
@media (width >= 35rem) {
	.video__controls-container {
		right: var(--space-30--px);
		bottom: var(--space-30--rem);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.video__controls-container {
		right: var(--space-40--px);
		bottom: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.video__controls-container {
		right: var(--space-60--px);
		bottom: var(--space-60--rem);
	}
}

.video__controls {
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	justify-content: end;
}

.video__controls .icon {
	display: block;
	width: 0.875rem;
	height: 0.625rem;
}

.video__play-pause-toggle__button {
	-mrh-resets: button;
	color: var(--color-foreground);
	margin-inline-end: -8px;
	margin-block-end: -8px;
	padding: 8px;
}

.video__play-pause-toggle .icon-pause,
[data-playing].video__play-pause-toggle .icon-arrow {
	display: none;
}

[data-playing].video__play-pause-toggle .icon-pause {
	display: block;
}
