.teaser-b {
	display: flex;
	flex-direction: column;
}

.teaser-b__meta {
	margin-block-start: var(--space-10--rem);
	order: 2;
	text-align: center;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-b__meta {
		margin-block-start: var(--space-12--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-b__meta {
		margin-block-start: var(--space-15--rem);
	}
}

.teaser-b__meta__title {
	display: inline;
	margin: 0;
}

.teaser-b__meta__client {
	display: inline;
	margin: 0;
}

.teaser-b__meta__director {
	margin: 0;
	margin-block-start: var(--space-5--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-b__meta__director {
		margin-block-start: var(--space-7--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-b__meta__director {
		margin-block-start: var(--space-10--rem);
	}
}

.teaser-b__image-wrapper {
	aspect-ratio: 16 / 9;
	order: 1;
	overflow: hidden;
	position: relative;
}

.teaser-b__image-wrapper:not(:has([data-loaded])) {
	background-color: var(--color-background);
}

.teaser-b__image-wrapper__overlay {
	box-sizing: border-box;
	display: flex;
	padding-inline: var(--space-10--px);
	padding-block-start: var(--space-10--rem);
	justify-content: space-between;
	align-items: flex-start;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-teaser-text-overlay);
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-b__image-wrapper__overlay {
		padding-inline: var(--space-15--px);
		padding-block-start: var(--space-15--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-b__image-wrapper__overlay {
		padding-inline: var(--space-20--px);
		padding-block-start: var(--space-20--rem);
	}
}

.teaser-b__image-wrapper__overlay__category-and-clients {
	border: 1px solid var(--color-foreground);
	padding-inline: var(--space-7--px);
	padding-block: var(--space-3--rem);
	white-space: nowrap;
	width: max-content;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.teaser-b__image-wrapper__overlay__category-and-clients {
		padding-inline: var(--space-12--px);
		padding-block-start: var(--space-5--rem);
		padding-block-end: var(--space-7--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.teaser-b__image-wrapper__overlay__category-and-clients {
		padding-inline: var(--space-15--px);
		padding-block-start: var(--space-7--rem);
		padding-block-end: var(--space-10--rem);
	}
}

.teaser-b__image-wrapper__overlay__category-and-clients[data-style="dashed"] {
	border-style: dashed;
	border-radius: var(--space-40--px);
}

.teaser-b__image-wrapper__thumbnail {
	object-fit: cover;
	transform-origin: center;
	transition: transform 256ms ease-in-out;
	width: 100%;
	height: 100%;
}

:any-link:hover .teaser-b__image-wrapper__thumbnail {
	transform: scale(1.15);
}
