.l-error {
	background-color: var(--color-background);
	display: grid;
	place-content: center;
	text-align: center;
	height: 100vh;
}

.l-error__main > :is(h1, p) {
	margin-block: var(--space-20--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.l-error__main > :is(h1, p) {
		margin-block: var(--space-30--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.l-error__main > :is(h1, p) {
		margin-block: var(--space-50--rem);
	}
}
