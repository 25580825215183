.button {
	-mrh-resets: button;
	background-color: var(--color-white);
	border-radius: 9999px;
	color: var(--color-cyan);
	font-size: 0.75rem;
	font-style: normal;
	letter-spacing: -0.24px;
	line-height: 1.4;
	padding-inline: var(--space-10--px);
	padding-block: var(--space-3--rem);
	transition: color 256ms;
}
/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.button {
		font-size: 0.875rem;
		letter-spacing: -0.28px;
		padding-inline: var(--space-12--px);
		padding-block: var(--space-4--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.button {
		font-size: 0.9375rem;
		letter-spacing: -0.3px;
		padding-inline: var(--space-15--px);
		padding-block: var(--space-5--rem);
	}
}

.button > span {
	display: inline-block;
	margin-trim: block;
	transition: transform 256ms;
}

.button:hover > span {
	transform: scale(1.1);
}
