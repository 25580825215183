.row {
	margin-top: var(--space-30--rem);
	margin-bottom: var(--space-30--rem);
	position: relative;

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-top: var(--space-60--rem);
		margin-bottom: var(--space-60--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-top: var(--space-100--rem);
		margin-bottom: var(--space-100--rem);
	}
}

.row[data-gap="small"] {
	margin-top: var(--space-10--rem);
	margin-bottom: var(--space-10--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (width >= 48rem) {
		margin-top: var(--space-15--rem);
		margin-bottom: var(--space-15--rem);
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-top: var(--space-20--rem);
		margin-bottom: var(--space-20--rem);
	}
}
