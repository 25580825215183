.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-bouncy-text {
	animation-fill-mode: both;
	animation-iteration-count: 1;
}

.u-bouncy-text [data-hover] span {
	animation: BOUNCE_TEXT 1280ms ease-in-out;
}

.u-bouncy-text span:nth-child(2) {
	animation-delay: calc(96ms * 1);
}

.u-bouncy-text span:nth-child(3) {
	animation-delay: calc(96ms * 2);
}

.u-bouncy-text span:nth-child(4) {
	animation-delay: calc(96ms * 3);
}

.u-bouncy-text span:nth-child(5) {
	animation-delay: calc(96ms * 4);
}

.u-bouncy-text span:nth-child(6) {
	animation-delay: calc(96ms * 5);
}

.u-bouncy-text span:nth-child(7) {
	animation-delay: calc(96ms * 6);
}

@keyframes BOUNCE_TEXT {
	15% {
		letter-spacing: -0.0625em;
		transform: scaleX(0.8);
	}

	40% {
		letter-spacing: 0.125em;
		transform: scaleX(1.4);
	}

	70% {
		letter-spacing: -0.0625em;
		transform: scaleX(0.7);
	}
}
