.form {
	box-sizing: border-box;
	font-size: 16px;
	font-size: max(16px, 1rem);
	width: 100%;
}

.form__group {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__group:not(:last-child) {
	margin-bottom: var(--space-32--rem);
}

.form__group__title {
	font-size: 1.5rem;
	font-weight: 700;
}

/* #region Form Row */
.form__row {
	margin: var(--space-16--rem) 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (width >= 64rem) {
	.form__row {
		margin: var(--space-32--rem) 0;
	}
}

/* Disabled */
.form__row--disabled .form__label {
	color: var(--color-grey-40);
}
/* #endregion Form Row */

/* #region Form Field */
.form__field-wrapper {
	display: flex;
	flex-flow: row;
	column-gap: 0.625rem;
	margin: 0;
	margin-block: var(--space-8--rem) var(--space-16--rem);
	align-items: center;
}
/* #endregion Form Field */

/* #region Form Label */
.form__label-wrapper {
	display: flex;
	flex-flow: column;
	margin-bottom: var(--space-16--rem);
	align-items: flex-start;
}

.form__label {
	font-weight: 500;
}
/* #endregion Form Label */

/* #region Input Types */
.form__text__input,
.form__textarea,
.form__select {
	border-radius: 0;
}

.form__date__input,
.form__select,
.form__text__input,
.form__textarea {
	background: none;
	border: 1px solid var(--color-foreground);
	box-sizing: border-box;
	color: var(--color-foreground);
	padding-inline: var(--space-7--px);
	padding-block: var(--space-5--rem);
	width: 100%;
}

/* from-small,tablet-p,min-width-768px */
@media (width >= 48rem) {
	.form__date__input,
	.form__select,
	.form__text__input,
	.form__textarea {
		padding-block: var(--space-7--rem);
	}
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.form__date__input,
	.form__select,
	.form__text__input,
	.form__textarea {
		padding-inline: var(--space-10--px);
		padding-block: var(--space-5--rem);
	}
}

.form__text__input::placeholder {
	color: var(--color-foreground);
	opacity: 0.5;
}

.form__select {
	-webkit-appearance: none;
}

.form__textarea {
	resize: vertical;
	height: 8rem;
}

.form__file__input {
	margin-top: var(--space-16--rem);
}

.form__text__input:disabled {
	color: var(--color-grey-40);
	cursor: not-allowed;
}
/* #endregion Input Types */

/* #region Checkbox + Radio */
.form__checkbox,
.form__radio {
	margin-bottom: var(--space-8--rem);
}

.form__checkbox__label,
.form__radio__label {
	line-height: 1.6;
}

/* Disabled */
.form__checkbox__input:disabled + .form__checkbox__label,
.form__radio__input:disabled + .form__radio__label {
	color: var(--color-grey-40);
	cursor: not-allowed;
}
/* #endregion Checkbox + Radio */

/* #region Form Buttons */
.form__file__input__button,
.form__submit__button {
	appearance: none;
	background-color: var(--color-foreground);
	border: none;
	color: var(--color-background);
	cursor: pointer;
	margin: 0;
	padding: var(--space-8--rem);
}
/* #endregion Form Buttons */

/* #region Warnings (Error + Optional) */
.form__error-label {
	color: var(--color-error);
	font-size: 0.875rem;
	font-style: italic;
	margin-top: var(--space-8--rem);
}

.form__row--has-error input:not(:focus) {
	border-color: var(--color-error);
}

.form__row--has-error .form__label {
	color: var(--color-error);
}

.form__label-subtext {
	color: var(--color-warning);
	font-size: 0.875rem;
	font-style: italic;
	margin-top: var(--space-8--rem);
}
/* #endregion Warnings (Error + Optional) */
